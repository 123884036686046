@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face{
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-ExtraBoldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
