/* ------- padding-top */
    .padding-top-150{
        padding-top: 150px;
    }
    .padding-top-100{
        padding-top: 100px;
    }
    .padding-top-70{
        padding-top: 70px;
    }
    .padding-top-60{
        padding-top: 60px;
    }
    .padding-top-50{
        padding-top: 50px;
    }
	.padding-top-40{
		padding-top: 40px;
	}
	.padding-top-30{
		padding-top: 30px;
	}
	.padding-top-25{
		padding-top: 25px;
	}
	.padding-top-20{
		padding-top: 20px;
	}
	.padding-top-10{
		padding-top: 10px;
	}
/* ------------------------ */


/* ------- padding-bottom */
    .padding-bottom-150{
        padding-bottom: 150px;
    }
    .padding-bottom-100{
        padding-bottom: 100px;
    }
    .padding-bottom-70{
        padding-bottom: 70px;
    }
    .padding-bottom-60{
        padding-bottom: 60px;
    }
    .padding-bottom-50{
        padding-bottom: 50px;
    }
	.padding-bottom-40{
		padding-bottom: 40px;
	}
	.padding-bottom-30{
		padding-bottom: 30px;
	}
	.padding-bottom-25{
		padding-bottom: 25px;
	}
	.padding-bottom-20{
		padding-bottom: 20px;
	}
	.padding-bottom-10{
		padding-bottom: 10px;
	}
/* ------------------------ */


/* ------- padding-left */
    .padding-left-150{
        padding-left: 150px;
    }
    .padding-left-100{
        padding-left: 100px;
    }
	.padding-left-80{
		padding-left: 80px;
	}
    .padding-left-70{
        padding-left: 70px;
    }
    .padding-left-60{
        padding-left: 60px;
    }
    .padding-left-50{
        padding-left: 50px;
    }
	.padding-left-40{
        padding-left: 40px;
    }
	.padding-left-30{
		padding-left: 30px;
	}
	.padding-left-20{
		padding-left: 20px;
	}
	.padding-left-10{
		padding-left: 10px;
	}
/* ------------------------ */


/* ------- padding-right */
    .padding-right-150{
        padding-right : 150px;
    }
    .padding-right-100{
        padding-right: 100px;
    }
    .padding-right-70{
        padding-right: 70px;
    }
    .padding-right-60{
        padding-right: 60px;
    }
    .padding-right-50{
        padding-right: 50px;
    }
	.padding-right-40{
        padding-right: 40px;
    }
	.padding-right-30{
		padding-right: 30px;
	}
	.padding-right-20{
		padding-right: 20px;
	}
	.padding-right-10{
		padding-right: 10px;
	}

/* ------------------------ */

.padding-none{
	padding: 0 !important;
}
.margin-none{
	margin: 0 !important;
}
/* ------- margin-top */
    .margin-top-10{
        margin-top: 10px;
    }
    .margin-top-15{
        margin-top: 15px;
    }
    .margin-top-20{
        margin-top: 20px;
    }
    .margin-top-25{
        margin-top: 25px;
    }
    .margin-top-30{
        margin-top: 30px;
    }
    .margin-top-40{
        margin-top: 40px;
    }
    .margin-top-50{
        margin-top: 50px;
    }
	.margin-top-70{
		margin-top: 70px;
	}
	.margin-top-100{
		margin-top: 100px;
	}
/* ------------------------ */


/* ------- margin-bottom */
	.margin-bottom-5{
		margin-bottom: 5px;
	}
    .margin-bottom-10{
        margin-bottom: 10px;
    }
    .margin-bottom-15{
        margin-bottom: 15px;
    }
    .margin-bottom-20{
        margin-bottom: 20px;
    }
    .margin-bottom-25{
        margin-bottom: 25px;
    }
    .margin-bottom-30{
        margin-bottom: 30px;
    }
    .margin-bottom-40{
        margin-bottom: 40px;
    }
    .margin-bottom-50{
        margin-bottom: 50px;
    }
	.margin-bottom-70{
		margin-bottom: 70px;
	}
    .margin-bottom-100{
        margin-bottom: 100px;
    }
	.margin-bottom-150{
        margin-bottom: 150px;
    }
/* ------------------------ */


/* ------- margin-left */
    .margin-left-10{
        margin-left: 10px;
    }
    .margin-left-15{
        margin-left: 15px;
    }
    .margin-left-20{
        margin-left: 20px;
    }
    .margin-left-25{
        margin-left: 25px;
    }
    .margin-left-30{
        margin-left: 30px;
    }
    .margin-left-40{
        margin-left: 40px;
    }
    .margin-left-50{
        margin-left: 50px;
    }
	.margin-left-70{
		margin-left: 50px;
	}
	.margin-left-100{
        margin-left: 100px;
    }
	.margin-left-150{
        margin-left: 150px;
    }
/* ------------------------ */


/* ------- margin-bottom */
    .margin-right-10{
        margin-right: 10px;
    }
    .margin-right-15{
        margin-right: 15px;
    }
    .margin-right-20{
        margin-right: 20px;
    }
    .margin-right-25{
        margin-right: 25px;
    }
    .margin-right-30{
        margin-right: 30px;
    }
    .margin-right-40{
        margin-right: 40px;
    }
    .margin-right-50{
        margin-right: 50px;
    }
	.margin-right-70{
		margin-right: 50px;
	}
	.margin-right-100{
		margin-right: 100px;
	}
/* ------------------------ */
