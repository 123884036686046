@import "constants";

html{
  position: relative;
  min-height: 100%;

  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
}
body{
  font-family: 'ProximaNova', sans-serif;
  font-size: 14px;
  line-height: 20px;

  font-weight: 400;
  color: $black;

  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

/* ------- bootstrap corrections */
.container{
  max-width: 1400px;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
select {
  outline: none;
}
/* ------------------------ */


/* ------- max-width */
.max-width-1440{
  max-width: 1440px;
}
.max-width-1280{
  max-width: 1280px;
}
.max-width-900{
  max-width: 900px;
}
.max-width-750{
  max-width: 750px;
}
.max-width-700{
  max-width: 700px;
}
.max-width-650{
  max-width: 650px;
}
.max-width-600{
  max-width: 600px;
}
.max-width-550{
  max-width: 550px;
}
.max-width-500{
  max-width: 500px;
}
.max-width-450{
  max-width: 450px;
}
.max-width-400{
  max-width: 400px;
}
.max-width-350{
  max-width: 3050px;
}
.max-width-300{
  max-width: 300px;
}
.max-width-250{
  max-width: 250px;
}
/* ------------------------ */


/* ------- backgrounds */
.background-black{
  background: $black;
}
.background-orange{
  background: $orange;
}
.background-green{
  background: $green;
}
.background-gray{
  background: $gray;
}
/* ------------------------ */


/* ------- h1, h2, h3, h4, h5, p, b */
h1, h2, h3, h4, h5, p, a {
  margin: 0;
  color:  #191A1E;
}
h1{
  font-size: 48px;
  line-height: 48px;

  font-weight: 600;
  text-transform: uppercase;
}
h2{
  font-size: 36px;
  line-height: 36px;

  font-weight: 600;
  text-transform: uppercase;
}
h3{
  font-size: 30px;
  line-height: 30px;

  font-weight: 600;
  text-transform: uppercase;
}
h4{
  font-size: 24px;
  line-height: 24px;

  font-weight: 500;
  text-transform: uppercase;
}
h5{
  font-size: 18px;
  line-height: 18px;

  font-weight: 500;
  text-transform: uppercase;
}
p{
  font-size: 18px;
  line-height: 24px;
}
b{
  font-weight: 600;
  color: inherit;
}
a{
  text-decoration: none !important;
}
a:hover{
  color: $green;
}
/* ------------------------ */


/* ------- button */
button:focus{
  outline: none;
}
.button{
  width: 180px;
  height: 50px;

  border-radius: 99px;
  border: none;
  background: none;

  font-weight: 600;
  text-transform: uppercase;

  filter: brightness(100%);
  transition: 0.25s ease;

  &:hover{
    filter: brightness(110%);
  }
}

.button:focus{
  outline: none;
}

.button.orange{
  color: #fff;
  background: $orange;
}
.button.green{
  color: #fff;
  background: $green;
}
.button.nofill{
  border: 1px solid $gray;

  &:hover{
    border: 1px solid $green !important;
    background: $green;

    color: white;
  }

}

.form-button{
  width: 100%;
  height: 40px;

  color: white;
  text-transform: uppercase;
  font-weight: 600;

  align-self: flex-end;

  background: $green;

  border: none;
  outline: none;

  filter: brightness(100%);
  transition: 0.25s ease;

  &:hover{
    filter: brightness(105%);
  }
}
/* ------------------------ */


/* ------- borders */
.border-top{
  border-top: 1px solid $gray;
}
.border-bottom{
  border-bottom: 1px solid $gray;
}
.border-left{
  border-left: 1px solid $gray;
}
.border-right{
  border-right: 1px solid $gray;
}
/* ------------------------ */


/* ------- border */
.border-black{
  border: 1px solid $black !important;
}
.border-orange{
  border: 1px solid $orange !important;
}
.border-green{
  border: 1px solid $green !important;
}
.border-gray{
  border: 1px solid $gray !important;
}
/* ------------------------ */


/* ------- borders */
.space{
  margin-top: 50px;
}
/* ------------------------ */


/* ------- hrs */
.hr-150{
  width: 150px;
  height: 1px;
  background: $gray;
}
.hr-100{
  width: 100px;
  height: 1px;
  background: $gray;
}
/* ------------------------ */


/* ------- icons */
.icon.circle{
  display: flex;
  padding: 9px;
  border-radius: 99px;
  border: 1px solid $gray;

  img{
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}
.icon-18{
  width: 18px;
  height: 18px;
}
.icon-phone{
  background: url("../images/icons/phone.svg");
  background-size: cover;
}
.icon-login{
  background: url("../images/icons/login.svg");
  background-size: cover;
}
.icon-in-circle{
  width: 36px;
  height: 36px;

  border-radius: 99px;
  border: 1px solid $gray;
}
.icon-money{
  background: url("../images/icons/money.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

.icon-and-title{
  display: flex;

  div{
    align-self: center;
  }
  .icon-18{
    margin-right: 10px;
  }
}
/* ------------------------ */


/* headings */
.line-before::before{
  position: absolute;
  content: '';

  left: 0;

  height: 100%;
  width: 3px;
}
.line-before{
  position: relative;
  padding-left: 15px;
}
.line-before.orange::before{
  background: $orange;
}
.line-before.green::before{
  background: $green;
}
/* ------------------------ */


/* forms */
.form-group{
  margin-bottom: 20px;
  width: 100%;
}
.form-control{
  height: 40px;

  border-radius: 0;
  border: 1px solid $gray;

  color: $black;
  font-size: inherit;

  padding: 0 15px;
}
.form-control::placeholder{
  color: $placeholder;
}
.form-check{
  padding: 0;
  margin: 0;
}
.form-check-input {
  display: none;
}

.form-check-input+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.form-check-input+label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid $gray;
  border-radius: 0;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.form-check-input:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}

.form-check-input:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.form-check-input:focus:not(:checked)+label::before {
  border-color: #80bdff;
}

.form-check-input:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.form-check-input:disabled+label::before {
  background-color: #e9ecef;
}
/* ------------------------ */


/* search */
.input-group.search{

  input.form-control{
    border-radius: 99px;
    padding: 0px 25px;

    text-transform: uppercase;
  }

  button.search-submit{
    margin-left: 10px;

    width: 40px;
    height: 40px;
    
    border-radius: 99px;
    border: 1px solid $gray;
    
    background: url("../images/icons/search.svg");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center;

    &:focus{
      outline: none;
    }
  }
}
/* ------------------------ */


/* acordion */
.card{
  border: 1px solid $gray;

  .card-toggle{
    width: 100%;
  }
  .card-header{
    padding: 20px;

    border: 0;
    background: none;

    border-bottom: 1px solid $gray;

    display: flex;
    justify-content: space-between;

    .card-title{
      margin: 0;
      padding: 0;

      font-size: 16px;
      font-weight: normal;

      cursor: pointer;
    }
  }
  .card-body{
    padding: 20px;

    border: 0;
  }
}

/* ------------------------ */


/* header */

header{
  .header-row{
    width: 100%;
    border-bottom: 1px solid $gray;
  }

  .header-row.top {
    line-height: 50px;

    .phone-mail{
      display: flex;
      .icon-and-title{
        padding-right: 20px;
        border-right: 1px solid $gray;
      }
      .mail{
        padding-left: 20px;
      }
    }
  }

  .header-row.main{
    div{
      align-self: center;
    }
    padding: 25px 0;

    .header-icons{
      .icon{
        margin-right: 10px;

        &:last-child{
          margin: 0;
        }
      }
    }
  }

  .header-row.menu{
    padding: 20px 0;
    nav.mainmenu{
      width: 100%;
      display: flex;
      justify-content: space-between;
      a.nav-link{
        padding: 0;
        text-transform: uppercase;
      }
    }
  }


}
/* ------------------------ */



/* mainpage */
.options {
  margin-top: 70px;
  margin-bottom: 70px;
}

.col.option {
  .option-image {
    width: 100%;
    text-align: center;

    img {
      height: 145px;
    }
  }

  .option-title {
    margin-top: 25px;
    width: 100%;
    text-align: center;
    a{
      text-transform: uppercase;
    }
  }
}

.about{
  position: relative;

  width: 100%;
  height: 330px;

  margin-top: 30px;
  margin-right: 50px;

  background: url("../images/about.png");

  .about-content{
    position: absolute;

    bottom: 0;
    left: 0;

    padding: 30px 50px;
    background: rgba(255,255,255,0.9);
    border-top-right-radius: 20px;

    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.partners{
  margin: 30px 0 0 0;

  display: flex;
  width: 100%;

  .col-4{
    display: flex;
    border: 1px solid $gray;
    text-align: center;
    height: 100px;

    img{
      align-self: center;
      margin: 0 auto;
    }

    margin-left: 15px;
    max-width: calc(33.3% - 30px);
    flex: 0 0 calc(33.3% - 30px);

    margin-bottom: 15px;

    &:nth-child(3n+1){
      margin-left: 0;
    }
  }
}

.key-product{
  margin: 0 0 40px 0;

  padding: 40px;
  border-radius: 20px;

  border: 1px solid $gray;

  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);

  p{
    font-size: 16px;
    line-height: 24px;

    margin: 20px 0;
  }

  img{
    margin: 0 auto;
    height: 100%;
  }

  .keyproduct-footer{
    display: flex;
    margin-top: 40px;

    padding-top: 15px;

    width: 100%;
    border-top: 1px solid $gray;

    nav{
      margin-left: 20px;
      display: flex;
      a.nav-link{
        padding: 0 15px;
      }
    }
  }
}
.key-product.reverse{
  .col-6:nth-child(1){
    order: 2;
  }
  .col-6:nth-child(2){
    order: 1;
  }
  .keyproduct-footer{
    order: 3;
  }
}
.review {
  margin-left: -40px;
  margin-right: -40px;

  .col-4 {
    border-right: 1px solid $gray;
    padding: 0px 40px;


    .avatar {
      height: 60px;
      width: 60px;

      border: 1px solid $gray;
      border-radius: 99px;

      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .review-content {
      width: calc(100% - 80px);

      .review-author{
        font-size: 16px;
        font-weight: 600;
      }
      .review-date{
        font-size: 12px;
      }
      .review-text{
        margin-top: 20px;
        font-size: 14px;
      }
    }

    &:last-child{
      border-right: 0;
    }
  }
}
.benefits {
  .col {
    .benefit-image {
      width: 100%;
      text-align: center;

      img {
        height: 145px;
      }
    }

    .benefit-title {
      margin: 25px auto 0 auto;
      max-width: 200px;

      text-align: center;
    }
  }
}
.cities{
  margin-left: -25px;
  margin-right: -25px;
  .col-3{
    flex: 0 0 calc(25% - 20px);
    max-width: calc(25% - 20px);

    border-radius: 10px;
    border: 1px solid $gray;
    padding: 0;

    margin: 10px;

    .city-image{
      width: 100%;

      img{
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .city{
      display: flex;
      justify-content: space-between;

      padding: 20px 20px;

      .city-title{
        font-size: 16px;
        font-weight: 600;
      }

      .city-price{
        font-size: 14px;
      }
    }
  }
}
.bottom-text{
  p{
    font-size: 16px;
  }
  .faq{
    margin-top: 30px;
    margin-left: -25px;
    margin-right: -25px;

    .col-4{
      flex: 0 0 calc(33.3% - 20px);
      max-width: calc(33.3% - 20px);
      margin: 10px;
    }
  }
}
/* ------------------------ */


/* footer */
footer{
  position: absolute;
  bottom: -396px;
  width: 100%;

  background: $gray;

  .footer-container{
    padding: 50px 0 50px 0;
  }
  ul.flex-column{
    li.nav-item{
      list-style: none;
      padding: 0;

      a.nav-link{
        padding: 0;
        line-height: 24px;
      }
    }
  }

  .col{
    margin: 0px 30px;

    .row{
      margin-left: -30px;
      margin-right: -30px;
    }

    &:last-child{
      max-width: 100px;
    }
  }


  .icon-36{
    display: flex;

    width: 36px;
    height: 36px;

    border: 2px solid $black;
    background: none;

    border-radius: 99px;

    margin-bottom: 10px;

    img{
      margin: 0 auto;
      align-self: center;

      width: 18px;
      height: 18px;
    }
  }

  .copyright{


    width: 100%;
    padding: 20px 0px;

    background: #fff;
  }
}
/* ------------------------ */

/* breadcrumbs */

.breadcrumbs{
  display: flex;

  a.nav-link{
    padding: 0;
    color: $placeholder;

    text-transform: uppercase;

    &::after{
      content: "—";
      margin: 0 5px;
    }

    &:last-child::after{
      content: "";
    }
  }
  a.nav-link.active{
    color: $green;
  }
}


.label{
  display: flex;

  text-transform: uppercase;
  font-weight: 600;

  .icon-info{
    margin-left: 10px;

    width: 18px;
    height: 18px;

    border-radius: 99px;
    background: url('../images/icons/info.svg') no-repeat center;

    cursor: pointer;
  }

}

.checkboxes{
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;

  .form-check{
    margin-right: 100px;
  }
}

.border-radius-99{
  border-radius: 99px;
}

.choice{
  display: flex;

  a.nav-link{
    padding: 0;
    margin: 0 20px;

    color: $black;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  a.nav-link.active{
    color: $green;
    border-bottom: 2px solid $green;
  }
}




.iframe{
  width: 100%;
  height: 550px;
  background: #F1F4E3;

  display: flex;

  img{
    height: 64px;
    width: 64px;

    margin: 0 auto;
    align-self: center;
  }
}
.scroll-x{
  width: 100%;
  max-height: 530px;

  overflow-x: scroll;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;

  h5{
    font-weight: 600;
  }

  &::-webkit-scrollbar {
    height: 10px;
    background: $gray;
    border-radius: 10px;

  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $green;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $green;
  }

  .col-4{
    margin-bottom: 30px;
  }
}
.select-block{
  width: 100%;
  margin-bottom: 10px;



  .select-content{
    display: flex;

    .select-icon{
      padding: 13px;

      border-radius: 99px;
      border: 1px solid $green;

      margin-right: 20px;

      img{
        margin: 0 auto;
        align-self: center;

        height: 24px;
        width: 24px;
      }
    }

    .select-text{
      align-self: center;
    }
  }
}

.news{
  margin-left: -25px;
  margin-right: -25px;
}
.news-block.col-8{
  flex: 0 0 calc(66.6% - 20px);
  max-width: calc(66.6% - 20px);
}
.news-block.col-4{
  flex: 0 0 calc(33.3% - 20px);
  max-width: calc(33.3% - 20px);
}
.news-block{
  margin: 0 10px 20px 10px;

  position: relative;
  padding: 0;

  .news-image{
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
  .news-block-content{
    max-width: 500px;

    z-index: 1;

    bottom: 0;
    left: 0;
    position: absolute;

    padding: 40px;

    .news-headline{
      font-size: 18px;
      font-weight: 600;

      color: white;
      transition: 0.2s ease;

      &:hover{
        color: $greenhover;
      }
    }
    .news-date{
      color: white;
      margin-top: 10px;
    }
  }

  &::before{
    content: '';
    position: absolute;

    z-index: 0;

    width: 100%;
    height: 100%;

    background: rgba(0,0,0,0.5);
  }
}



/*-*/